window.addEventListener('load', (event) => {
  const programs = document.querySelectorAll('.tracks');

  if (programs) {
    programs.forEach((program) => {
      const heights = [];
      const tracks = program.querySelectorAll('.track');

      tracks.forEach((track) => {
        const paragraph = track.querySelector('p');

        heights.push(paragraph.offsetHeight);
      });
    });
  }
});
