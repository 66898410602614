// window.onload = (event) => {
//   let slides = document.querySelectorAll('.mySlides');
//   let slideIndex = 0;

//   if (slides) {
//     showSlides();

//     function showSlides() {
//       let i;

//       // let dots = document.getElementsByClassName('dot');
//       slides.forEach((slide) => {
//         slide.classList.remove('slide');
//         //slides[i].style.display = 'none';

//         slide.classList.remove('show');
//       });

//       slideIndex++;
//       if (slideIndex > slides.length) {
//         slideIndex = 1;
//       }
//       if (slides[slideIndex - 1]) {
//         //   setTimeout(() => {
//         //     console.log(slides[slideIndex - 1]);
//         //   }, 100);
//         slides[slideIndex - 1].classList.add('show');

//         slides[slideIndex - 1].classList.add('slide');
//       } else {
//       }

//       //slides[slideIndex - 1].style.display = 'block';

//       setTimeout(showSlides, 5000);
//     }
//   }
// };

import 'slick-carousel/slick/slick';
// import Splitting from 'splitting';

function initSlider(id) {
  $('#' + id).slick({
    infinite: true,
    dots: false,
    speed: 700,
    autoplay: true,
    autoplaySpeed: 3000,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow:
      '<button type="button" class="slick-next" aria-label="Next slide"><svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 23.67 40.58" style="enable-background:new 0 0 23.67 40.58;" xml:space="preserve"><path fill="#FFFFFF" opacity="0.85" d="M22.63,22.55L5.65,39.54c-1.26,1.26-3.3,1.26-4.55,0c-1.26-1.26-1.26-3.29,0-4.55l14.71-14.72L1.09,5.56 c-1.26-1.26-1.26-3.3,0-4.55c0.63-0.63,1.45-0.94,2.27-0.94s1.65,0.31,2.28,0.94L22.63,18c0.63,0.63,0.94,1.45,0.94,2.28 C23.57,21.1,23.26,21.92,22.63,22.55"/></svg></button>',
    prevArrow:
      '<button type="button" class="slick-prev" aria-label="Previous slide"><svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 23.67 40.58" style="enable-background:new 0 0 23.67 40.58;" xml:space="preserve"><path fill="#FFFFFF" opacity="0.85" d="M1.08,18.01L18.06,1.02c1.26-1.26,3.3-1.26,4.55,0c1.26,1.26,1.26,3.29,0,4.55L7.9,20.29L22.62,35 c1.26,1.26,1.26,3.3,0,4.55c-0.63,0.63-1.45,0.94-2.27,0.94c-0.82,0-1.65-0.31-2.28-0.94L1.08,22.56c-0.63-0.63-0.94-1.45-0.94-2.28 C0.14,19.46,0.45,18.64,1.08,18.01"/></svg></button>',
  });

  let $currentTitle;
  let $slides = $('.slick-slide:not(.slick-cloned)');
  let $firstCurrent = $('.slick-current').find('.title');
  let $beforeSplitting = [];
  $slides.each(function (index) {
    $beforeSplitting[index] = $(this).find('.title').html();
  });
  //Splitting({ target: $firstCurrent });

  if ($('.slick-current').find('a.btn-link').length > 0) {
    $('.slick-current').find('a.btn-link').addClass('show');
  }

  $('#' + id).on(
    'afterChange',
    function (event, slick, currentSlide, nextSlide) {
      $('.slick-slide').find('a.btn-link').removeClass('show');

      // $slides.each(function (index) {
      //   if (index != slick.currentSlide) {
      //     $(this).find('.title').remove();
      //     $(this)
      //       .find('.content')
      //       .prepend(
      //         '<div class="title" data-splitting>' +
      //           $beforeSplitting[index] +
      //           '</div>'
      //       );
      //   }
      // });

      if ($('.slick-current').find('a.btn-link').length > 0) {
        $('.slick-current').find('a.btn-link').addClass('show');
        console.log('hello');
      }
      // Splitting({ target: $currentTitle });
    }
  );
}
window.initSlider = initSlider;
