import { intersectionObserve } from './_intersection-observer';

let sections = document.querySelectorAll('#content-section section');
const hero = sections[0];
const header = document.querySelector('header');
const fixedButton = document.querySelector('.fixed-button');
const wrapper = document.querySelector('.fixed-button .wrapper');
// const dropdowns = document.querySelectorAll('.current_page_item ul');
// const dropdown = dropdowns[1];

//Normal open close of menu

if (fixedButton) {
  const navBar = document.querySelector('header');

  fixedButton.style.top = hero.offsetHeight + 'px';

  $(window).one('scroll', function () {
    $(window).scroll(function (e) {
      var timer = null;
      if (timer !== null) {
        clearTimeout(timer);
      }
      let currentScroll =
        document.documentElement.scrollTop || document.body.scrollTop;

      if (hero.offsetHeight - navBar.offsetHeight < currentScroll) {
        fixedButton.classList.add('show');
        fixedButton.style.top = navBar.offsetHeight + 'px';
      }
      if (hero.offsetHeight - navBar.offsetHeight > currentScroll) {
        fixedButton.classList.remove('show');
        fixedButton.style.top = hero.offsetHeight + 'px';
      }
    });
  });
}
