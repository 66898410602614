const buttons = document.querySelectorAll('.speakers .blue button');
const speakerButton = document.querySelectorAll('.speaker-popup button');

if (buttons) {
  const speakers = document.querySelectorAll('.speakers .speaker-popup');

  function showSpeaker(id) {
    var hasShowClass = false;

    speakers.forEach((speaker) => {
      if (speaker.classList.contains('show')) {
        hasShowClass = true;
      }
    });

    if (hasShowClass) {
      speakers.forEach((speaker) => {
        if (speaker.classList.contains('show')) {
          speaker.classList.remove('show');
          speaker.parentNode.style.height = speaker.offsetHeight + 'px';
          return;
        }

        if (
          speaker.getAttribute('data-speaker') === id &&
          speaker.classList.contains('show')
        ) {
          setTimeout(() => {
            speaker.classList.remove('show');
            speaker.parentNode.style.height = speaker.offsetHeight + 'px';
          }, 800);
        }

        if (
          speaker.getAttribute('data-speaker') === id &&
          !speaker.classList.contains('show')
        ) {
          setTimeout(() => {
            speaker.classList.add('show');
            speaker.parentNode.style.height = speaker.offsetHeight + 'px';
          }, 800);
        }
        if (speaker.getAttribute('data-speaker') != id) {
          speaker.classList.remove('show');
          speaker.parentNode.style.height = speaker.offsetHeight + 'px';
        }
      });
    } else {
      speakers.forEach((speaker) => {
        if (speaker.getAttribute('data-speaker') === id) {
          if (speaker.classList.contains('show')) {
            speaker.classList.remove('show');
            speaker.parentNode.style.height = speaker.offsetHeight + 'px';
          } else {
            speaker.classList.add('show');
            speaker.parentNode.style.height = speaker.offsetHeight + 'px';
          }
        }
      });
    }
  }

  buttons.forEach((button) => {
    button.onclick = () => {
      showSpeaker(button.getAttribute('data-speaker'));
    };
  });
}

if (speakerButton) {
  function hideSpeaker(button) {
    const speaker = button.parentNode.parentNode;
    speaker.classList.remove('show');
    speaker.parentNode.style.height = speaker.offsetHeight + 'px';
  }

  speakerButton.forEach((button) => {
    button.onclick = () => {
      hideSpeaker(button);
    };
  });
}
