onload = (event) => {
  const buttons = document.querySelectorAll('.program-btn');

  if (buttons) {
    buttons.forEach((button) => {
      const id = button.getAttribute('data-target');

      const target = document.getElementById(id);

      button.addEventListener('click', function () {
        target.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
          inline: 'nearest',
        });
      });
    });
  }
};
