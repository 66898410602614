import { MarkerClusterer } from "@googlemaps/markerclusterer";

let map = null;
let zoomControlDiv = null;
let a15Bounds;
let a60Bounds;

const mapIcons = {
    circle: '<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30" style="enable-background:new 0 0 30 30;" xml:space="preserve"><circle class="st0" cx="15" cy="15" r="15" fill="#0F3F5A"/></svg>\n',
    hotel:`<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
    viewBox="0 0 640 512" style="enable-background:new 0 0 640 512;" xml:space="preserve">
<circle cx="320" cy="256" r="238.6" fill="#103F5A"/>
<path fill="#FFFFFF" d="M187.7,153.1c8.1,0,14.7,6.6,14.7,14.7v117.6h102.9v-73.5c0-8.1,6.6-14.7,14.7-14.7h102.9
   c24.3,0,44.1,19.8,44.1,44.1v102.9c0,8.1-6.6,14.7-14.7,14.7c-8.1,0-14.7-6.6-14.7-14.7v-14.7H334.7H320H202.4v14.7
   c0,8.1-6.6,14.7-14.7,14.7s-14.7-6.6-14.7-14.7V167.8C173,159.7,179.6,153.1,187.7,153.1z M253.9,197.2c20.3,0,36.8,16.5,36.8,36.8
   s-16.5,36.8-36.8,36.8c-20.3,0-36.8-16.5-36.8-36.8S233.6,197.2,253.9,197.2z"/>
</svg>`,
    airport:`<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
    viewBox="0 0 640 512" style="enable-background:new 0 0 640 512;" xml:space="preserve">
<circle cx="320" cy="256" r="238.6" fill="#103F5A"/>
<path fill="#FFFFFF" d="M445.3,218.8c19.9,0,54.4,16.8,54.4,37.2c0,20.9-34.5,37.2-54.4,37.2h-67.7l-58.3,102.1
   c-3.3,5.8-9.5,9.3-16.1,9.3h-32.6c-6.2,0-10.6-5.9-8.9-11.8l28.4-99.6h-59.7l-25.1,33.4c-1.7,2.3-4.5,3.7-7.4,3.7h-24.4
   c-4.5,0-8.1-3.7-8.1-8.1c0-0.8,0.1-1.5,0.3-2.3l18.3-63.9l-18.3-63.9c-0.2-0.8-0.3-1.5-0.3-2.3c0-4.5,3.7-8.1,8.1-8.1h24.4
   c2.9,0,5.7,1.4,7.4,3.7l25.1,33.4h59.7l-28.4-99.6c-1.7-5.9,2.7-11.8,8.9-11.8h32.6c6.7,0,12.8,3.6,16.1,9.3l58.3,102.1H445.3
   L445.3,218.8z"/>
</svg>`,
    industry:`<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
    viewBox="0 0 640 512" style="enable-background:new 0 0 640 512;" xml:space="preserve">
<circle cx="320" cy="256" r="238.6" fill="#103F5A"/>
<path fill="#FFFFFF" d="M233.9,103c-15.8,0-28.7,12.8-28.7,28.7v248.6c0,15.8,12.8,28.7,28.7,28.7h57.4v-47.8
   c0-15.8,12.8-28.7,28.7-28.7s28.7,12.8,28.7,28.7V409h57.4c15.8,0,28.7-12.8,28.7-28.7V131.7c0-15.8-12.8-28.7-28.7-28.7H233.9z
    M243.5,246.4c0-5.3,4.3-9.6,9.6-9.6h19.1c5.3,0,9.6,4.3,9.6,9.6v19.1c0,5.3-4.3,9.6-9.6,9.6h-19.1c-5.3,0-9.6-4.3-9.6-9.6V246.4z
    M310.4,236.9h19.1c5.3,0,9.6,4.3,9.6,9.6v19.1c0,5.3-4.3,9.6-9.6,9.6h-19.1c-5.3,0-9.6-4.3-9.6-9.6v-19.1
   C300.9,241.2,305.2,236.9,310.4,236.9z M358.2,246.4c0-5.3,4.3-9.6,9.6-9.6h19.1c5.3,0,9.6,4.3,9.6,9.6v19.1c0,5.3-4.3,9.6-9.6,9.6
   h-19.1c-5.3,0-9.6-4.3-9.6-9.6V246.4z M253.1,160.4h19.1c5.3,0,9.6,4.3,9.6,9.6v19.1c0,5.3-4.3,9.6-9.6,9.6h-19.1
   c-5.3,0-9.6-4.3-9.6-9.6v-19.1C243.5,164.7,247.8,160.4,253.1,160.4z M300.9,169.9c0-5.3,4.3-9.6,9.6-9.6h19.1
   c5.3,0,9.6,4.3,9.6,9.6v19.1c0,5.3-4.3,9.6-9.6,9.6h-19.1c-5.3,0-9.6-4.3-9.6-9.6V169.9z M367.8,160.4h19.1c5.3,0,9.6,4.3,9.6,9.6
   v19.1c0,5.3-4.3,9.6-9.6,9.6h-19.1c-5.3,0-9.6-4.3-9.6-9.6v-19.1C358.2,164.7,362.6,160.4,367.8,160.4z"/>
</svg>`,
    restaurant: `<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                     xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                     viewBox="0 0 640 512" style="enable-background:new 0 0 640 512;" xml:space="preserve">
        <circle cx="320" cy="256" r="238.6" fill="#103F5A"/>
        <path fill="#FFFFFF" d="M415.9,140.8c2.9-2.9,3.2-7.6,0.6-10.8c-2.6-3.2-7.3-3.9-10.7-1.6l-53.7,35.8c-14,9.4-22.5,25.1-22.5,42
	c0,9.7,2.8,19.1,7.9,27.2L213.4,357.5c-6.3,6.3-6.3,16.5,0,22.8c6.3,6.3,16.5,6.3,22.8,0l124.1-124.1c8.1,5.1,17.5,7.9,27.2,7.9
	c16.9,0,32.6-8.5,42-22.5l35.8-53.7c2.3-3.4,1.6-8.1-1.6-10.7c-3.2-2.6-7.9-2.4-10.8,0.6l-50.7,50.7l-9.2-9.2l47.5-54.8
	c2.8-3.2,2.6-8-0.4-11s-7.8-3.2-11-0.4l-54.8,47.5l-9.2-9.2L415.9,140.8z M202.9,130.7c-2.3-2.4-5.5-3.7-8.9-3.7
	c-5.6,0-10.6,3.8-11.8,9.3c-2.4,10.2-5.7,26.8-5.7,39.1c0,42,24.3,65.7,64.9,105.5c3.3,3.2,6.7,6.5,10.2,10c1,0.9,1.9,1.8,3,2.6
	l55.6-55.6L202.9,130.7z M406.9,380.2c6.3,6.3,16.5,6.3,22.8,0c6.3-6.3,6.3-16.5,0-22.8l-74-73.9l-22.8,22.8L406.9,380.2z"/>
</svg>`,
    attraction: `<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                     xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                     viewBox="0 0 640 512" style="enable-background:new 0 0 640 512;" xml:space="preserve">
        <circle cx="320" cy="256" r="238.6" fill="#103F5A"/>
        <path fill="#FFFFFF" d="M251.7,136.4l-6.4,19.2h-46c-21.7,0-39.4,17.7-39.4,39.4v157.5c0,21.7,17.7,39.4,39.4,39.4h236.2
	c21.7,0,39.4-17.7,39.4-39.4V194.9c0-21.7-17.7-39.4-39.4-39.4h-46l-6.4-19.2c-4-12.1-15.3-20.2-28-20.2h-75.6
	C267,116.2,255.7,124.3,251.7,136.4z M317.5,214.6c32.6,0,59.1,26.4,59.1,59.1s-26.4,59.1-59.1,59.1s-59.1-26.4-59.1-59.1
	S284.9,214.6,317.5,214.6z"/>
</svg>`,
    conferenceArea: `<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                            viewBox="0 0 640 512" style="enable-background:new 0 0 640 512;" xml:space="preserve">
<circle cx="320" cy="256" r="238.6" fill="#103F5A"/>
        <g>
	<path fill="#FCFCFC" stroke="#FCFCFC" stroke-width="7" stroke-miterlimit="10" d="M405.6,332.2l43.3-59.5c1.9-2.6,2.9-5.4,2.8-8.2c-0.1-2.8-1.2-4.9-3.3-6.4c-2.1-1.5-4.4-1.9-7.1-1.1
		c-2.6,0.8-4.9,2.5-6.8,5.3L394.6,317l-80.8-111.5l0,0c6.7-6.7,11.8-14.7,15.4-23.7c3.6-9.2,5.5-19.1,5.5-30.1s-1.8-20.8-5.5-30
		s-8.9-17.1-15.4-23.7c-6.7-6.7-14.4-11.9-23.6-15.5c-9.2-3.6-19-5.5-30-5.5c-11,0-20.9,1.8-30.1,5.5c-9.2,3.7-16.9,8.9-23.4,15.5
		c-6.5,6.8-11.6,14.7-15.3,23.7c-3.7,9.2-5.5,19-5.5,30s1.8,20.9,5.5,30.1c3.6,9.2,8.7,17.1,15.3,23.7c6.5,6.8,14.4,11.9,23.4,15.7
		c9.2,3.6,19.1,5.5,30.1,5.5c11,0,20.8-1.8,30-5.5c3.3-1.4,6.5-2.9,9.6-4.7l83.9,115.8l-45.3,62.4c-1.9,2.6-2.9,5.4-2.9,8
		c0,2.6,1.1,4.9,3.2,6.4c2.1,1.5,4.4,1.9,7.1,1.2c2.6-0.7,4.9-2.5,6.9-5.1l41.9-57.7l42,58c1.9,2.6,4.3,4.4,6.9,5.1
		c2.6,0.7,5,0.4,7.1-1.2c2.1-1.5,3.2-3.6,3.2-6.4s-1-5.4-2.9-8L405.6,332.2z M283.1,205.8c-6.9,3.1-14.6,4.4-22.7,4.4
		c-8.2,0-15.9-1.5-23-4.4c-6.9-3.1-13-7.1-18-12.5c-5-5.3-9-11.5-11.9-18.6s-4.3-14.8-4.3-23.2s1.4-15.9,4.3-23
		c2.9-7.2,6.8-13.5,11.9-18.7c5-5.3,11.1-9.4,18-12.3c6.9-2.9,14.7-4.4,23-4.4c8.3,0,15.8,1.5,22.7,4.4c6.9,2.9,13,7.1,18.2,12.3
		s9.2,11.5,11.9,18.7c2.8,7.2,4.2,14.8,4.2,23c0,8.2-1.4,16.1-4.2,23.2c-2.4,6.1-5.7,11.6-9.8,16.4l-15-20.7
		c-1.9-2.6-4.3-4.4-6.8-5.3c-2.6-0.8-4.9-0.4-7.1,1.1c-2.1,1.5-3.2,3.6-3.3,6.4c-0.1,2.8,0.8,5.4,2.8,8.2l15.7,21.6
		C287.6,203.7,285.5,204.9,283.1,205.8L283.1,205.8z"/>
</g>
</svg>`

}

const styles = [
    {
        "featureType": "all",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "weight": "2.00"
            }
        ]
    },
    {
        "featureType": "all",
        "elementType": "geometry.stroke",
        "stylers": [
            {
                "color": "#9c9c9c"
            }
        ]
    },
    {
        "featureType": "all",
        "elementType": "labels.text",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "landscape",
        "elementType": "all",
        "stylers": [
            {
                "color": "#f2f2f2"
            }
        ]
    },
    {
        "featureType": "landscape",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#ffffff"
            }
        ]
    },
    {
        "featureType": "landscape.man_made",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#ffffff"
            },
            {
                "visibility": "on"
            }
        ]
    },
    {
        "featureType": "poi",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "road",
        "elementType": "all",
        "stylers": [
            {
                "saturation": -100
            },
            {
                "lightness": 45
            }
        ]
    },
    {
        "featureType": "road",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#eeeeee"
            }
        ]
    },
    {
        "featureType": "road",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#7b7b7b"
            }
        ]
    },
    {
        "featureType": "road",
        "elementType": "labels.text.stroke",
        "stylers": [
            {
                "color": "#ffffff"
            }
        ]
    },
    {
        "featureType": "road.highway",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "simplified"
            }
        ]
    },
    {
        "featureType": "road.arterial",
        "elementType": "labels.icon",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "transit",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "water",
        "elementType": "all",
        "stylers": [
            {
                "color": "#46bcec"
            },
            {
                "visibility": "on"
            }
        ]
    },
    {
        "featureType": "water",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#c8d7d4"
            }
        ]
    },
    {
        "featureType": "water",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#070707"
            },
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "water",
        "elementType": "labels.text.stroke",
        "stylers": [
            {
                "color": "#ffffff"
            }
        ]
    }
];

/**
 * initMap
 *
 * Renders a Google Map onto the selected jQuery element
 *
 * @date    22/10/19
 * @since   5.8.6
 *
 * @param   jQuery $el The jQuery element.
 * @return  object The map instance.
 */
function initMap($el) {
    const a15MinSW = new google.maps.LatLng(58.912704, 5.610856);
    const a15MinNE = new google.maps.LatLng(59.025141, 5.878318);
    a15Bounds = new google.maps.LatLngBounds(a15MinSW, a15MinNE);

    const a60MinSW = new google.maps.LatLng(58.629517, 5.524057);
    const a60MinNE = new google.maps.LatLng(59.239138, 6.376866);
    a60Bounds = new google.maps.LatLngBounds(a60MinSW, a60MinNE);

    const aquaNextLocation = {
        lat: 58.9546197,
        lng: 5.6961458
    };

    let markers = [];

    // Find marker elements within map.
    const $markers = $el.find('.marker');

    zoomControlDiv = $el.find('.custom-controls')[0];

    // Create gerenic map.
    const mapArgs = {
        center: aquaNextLocation,
        zoom: 13,
        mapTypeId: google.maps.MapTypeId.ROADMAP,
        styles: styles,
        disableDefaultUI: true,
        zoomControl: true,
        fullscreenControl: true

    };
    map = new google.maps.Map($el[0], mapArgs);

    map.fitBounds(a15Bounds);

    // Add markers.
    $markers.each(function () {
        const marker = initMarker($(this), map);
        markers.push(marker)
    });

    // Add custom markers
    const zoomControl15min = createZoomControl(15, "15 min", map);
    zoomControl15min.className = "active";
    const zoomControl60min = createZoomControl(60, "60 min", map);

    zoomControlDiv.appendChild(zoomControl15min);
    zoomControlDiv.appendChild(zoomControl60min);

    // Add the control to the map at a designated control position
    // by pushing it on the position's array. This code will
    // implicitly add the control to the DOM, through the Map
    // object. You should not attach the control manually.
    map.controls[google.maps.ControlPosition.TOP_LEFT].push(zoomControlDiv);

    // Marker clustering
    //new MarkerClusterer({ map, markers, renderer: new CustomRenderer() });

    // Return map instance.
    return map;
}

/**
 * initMarker
 *
 * Creates a marker for the given jQuery element and map.
 *
 * @date    22/10/19
 * @since   5.8.6
 *
 * @param   jQuery $el The jQuery element.
 * @param   object The map instance.
 * @return  object The marker instance.
 */
function initMarker($marker) {
    // Get position from marker.
    const lat = $marker.data('lat');
    const lng = $marker.data('lng');
    const type = $marker.data('type');
    const name = $marker.data('name');
    const latLng = {
        lat: parseFloat(lat),
        lng: parseFloat(lng)
    };

    let url = 'data:image/svg+xml;charset=UTF-8;base64,' + btoa(mapIcons.circle);
    let scaledSize = new google.maps.Size(30, 30);
    let anchor = null;

    switch (type) {
        case 'hotel':
            url = 'data:image/svg+xml;charset=UTF-8;base64,' + btoa(mapIcons.hotel);
            break;
        case 'airport':
            url = 'data:image/svg+xml;charset=UTF-8;base64,' + btoa(mapIcons.airport);
            scaledSize = new google.maps.Size(45, 45);
            break;
        case 'industry':
                url = 'data:image/svg+xml;charset=UTF-8;base64,' + btoa(mapIcons.industry);
                break;
        case 'conference-area':
            url = 'data:image/svg+xml;charset=UTF-8;base64,' + btoa(mapIcons.conferenceArea);
            scaledSize = new google.maps.Size(45, 45);
            break;
        case 'restaurant':
            url = 'data:image/svg+xml;charset=UTF-8;base64,' + btoa(mapIcons.restaurant);
            scaledSize = new google.maps.Size(45, 45);
            break;
        case 'attraction':
            url = 'data:image/svg+xml;charset=UTF-8;base64,' + btoa(mapIcons.attraction);
            break;
    }

    const icon = {
        url: url,
        scaledSize: scaledSize, // scaled size
        optimized: false,
        anchor: anchor
    };

    // Create marker instance.
    const marker = new google.maps.Marker({
        position: latLng,
        icon: icon,
        map: map,
        /*label: {
            text: name,
            className: 'marker-label'
        },*/
    });

    // If marker contains HTML, add it to an infoWindow.
    if ($marker.html()) {

        // Create info window.
        var infowindow = new google.maps.InfoWindow({
            content: $marker.html()
        });

        // Show info window when marker is clicked.
        google.maps.event.addListener(marker, 'click', function () {
            infowindow.open(map, marker);
        });
    }

    return marker;
}

function createZoomControl(zoom, title, map) {
    const controlButton = document.createElement("button");

    controlButton.textContent = title;
    controlButton.title = "Click to zoom the map";
    controlButton.type = "button";

    // Setup the click event listeners: simply set the map to Chicago.
    controlButton.addEventListener("click", () => {
        switch (zoom) {
            case 15:
                map.fitBounds(a15Bounds);
                break;
            case 60:
                map.fitBounds(a60Bounds);
                break;
        }

        Array.from(zoomControlDiv.children).forEach(function(el) {
            el.classList.remove('active')
        })

        controlButton.classList.add('active');
    });

    return controlButton;
}

class CustomRenderer {
    render({ count, position}) {

// create svg url with fill color
        const svg = window.btoa(`
<svg fill="#103F5A" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 240 240">
  <circle cx="120" cy="120" opacity=".6" r="70" />
  <circle cx="120" cy="120" opacity=".3" r="90" />
  <circle cx="120" cy="120" opacity=".2" r="110" />
  <circle cx="120" cy="120" opacity=".1" r="130" />
</svg>`);

// create marker using svg icon
        return new google.maps.Marker({
            position,
            icon: {
                url: `data:image/svg+xml;base64,${svg}`,
                scaledSize: new google.maps.Size(45, 45),
            },
            label: {
                text: String(count),
                color: "rgba(255,255,255,0.9)",
                fontSize: "12px",
            },
            // adjust zIndex to be above other markers
            zIndex: 1000 + count,
        });
    }
}

window.initMaps = function() {
// Render maps on page load.
    $(document).ready(function () {
        $('.acf-map').each(function () {
            initMap($(this));
        });
    }); 
}
