const buttonArrows = document.querySelectorAll(
  '.menu-item-has-children button'
);
const menuItem = document.querySelectorAll('.menu-item-has-children');

//new

const menuList = document.querySelector('.menu-list');
const links = document.querySelectorAll('.link-wrapper a');

links.forEach((link) => {
  const url = link.href;
  let slug = (url) => new URL(url).pathname.match(/[^\/]+/g);
  if (url.includes('#')) {
    // console.log(url);
    link.removeAttribute('href');
  }
});
// if (screen.width > 1199) {
//   // menuList.style.height = menuList.offsetHeight + 'px';
//   // onresize = (event) => {
//   //   menuList.style.height = menuList.offsetHeight + 'px';
//   // };
//   //   menuItem.forEach((item) => {
//   //   //get link-wrapper
//   //   const linkWrapper = item.querySelector('.link-wrapper');
//   //   console.log(linkWrapper.offsetWidth + 'px');
//   //   item.style.width = linkWrapper.offsetWidth + 'px';
//   // });
// }

if (screen.width > 1289) {
}

$(window).resize(function () {
  //alert($(window).width());
  if ($(window).width() < 1289) {
  } else {
    menuItem.forEach((item) => {
      //get link-wrapper
      const linkWrapper = item.querySelector('.link-wrapper');

      item.style.width = linkWrapper.offsetWidth + 'px';
    });
  }
});

if ($(window).width() < 1289) {
} else {
  menuItem.forEach((item) => {
    //get link-wrapper
    const linkWrapper = item.querySelector('.link-wrapper');

    item.style.width = linkWrapper.offsetWidth + 'px';
  });
}

if (buttonArrows) {
  buttonArrows.forEach((button) => {
    button.onclick = function () {
      const list = this.parentElement.parentElement;
      // const submenu = parent.nextElementSibling;

      // submenu.classList.toggle('show');
      menuItem.forEach((item) => {
        if (item != list) {
          item.classList.remove('expand');
        }
      });
      if (list.classList.contains('expand')) {
        list.classList.remove('expand');
      } else {
        list.classList.add('expand');
      }
    };
  });
}

//old

window.onload = (event) => {
  //  console.log("page is fully loaded");
  //check if link is just #
  // const links = document.querySelectorAll(".nav-menu a");
  // links.forEach((link) => {
  //   const url = link.href;
  //   let slug = (url) => new URL(url).pathname.match(/[^\/]+/g);
  //   if (url.includes("#")) {
  //     // console.log(url);
  //     link.removeAttribute("href");
  //   }
  // });
  // // every time you leave(blur) a link in side menu, wait a few sec and check if other has focus, if not, close menu
  // $(".sub-menu a").blur(function () {
  //   setTimeout(function () {
  //     if (!$(".sub-menu a").is(":focus")) {
  //       setTimeout(function () {
  //         $(".sub-menu").css({
  //           display: "none",
  //         });
  //       }, 500);
  //       $(".menu-item-has-children").removeClass("submenu-open");
  //     }
  //   }, 100);
  // });
};
